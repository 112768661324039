export default [
      {
        path: '/apps/erp-crm',
        name: 'erp-crm',
        component: () => import('@/views/erp-crm/ErpCrm.vue'),
        meta: {
          pageTitle: 'ERP & CRM',
          breadcrumb: [
            {
              text: 'Companies',
              active: true,
            },
          ],
        },
      },

      {
        path: '/apps/erp-crm-pricing',
        name: 'erp-crm-pricing',
        component: () => import('@/views/erp-crm/ErpCrmPricing.vue'),
        meta: {
          pageTitle: 'ERP & CRM',
          breadcrumb: [
            {
              text: 'Companies',
            },
            {
                text: 'Pricing',
                active: true,
              },
          ],
        },
      },

      {
        path: '/apps/erp-crm-pay',
        name: 'erp-crm-pay',
        component: () => import('@/views/erp-crm/ErpCrmPay.vue'),
        meta: {
          pageTitle: 'ERP & CRM',
          breadcrumb: [
            {
              text: 'Companies',
            },
            {
                text: 'Order',
                active: true,
              },
          ],
        },
      },      

      

  ]
  